/* Variables */
:root {
  --primary: #1aac83;
  --secondary: white;
  --tertiary: black;
  --quaternary: #0d6efd;
  --error: #e7195a;
}

/* Reset/Global Styles */
html {
  overflow-y: hidden;
}

body {
  font-family: 'Times New Roman', Times, serif;
  background: url('./images/landscaping.png') no-repeat;
  background-size: cover;
  background-attachment: fixed;
  overflow-x: hidden; 
  height: 100vh;
}

/* Base Elements */
header {
  background: var(--primary);
  border-bottom: 1px solid #ddd;
}

header .container {
  padding: 10px 5px;
  display: flex;
  white-space: nowrap;
  overflow-x: auto;
}

header .logo {
  color: var(--secondary);
  cursor: pointer;
  margin-right: 20px;
  border-radius: 8px;
}

header a {
  color: var(--secondary);
  text-decoration: none;
}

header h2 {
  margin: 0px;
}

header a:hover {
  text-decoration: underline;
}

@media (max-width: 995px) {
  header .container {
    align-items: center;
    padding: 10px 20px 10px 20px;
    overflow-x: hidden;
  }

  .nav-right-controls span {
    display: none; 
  }
}

label, input, select {
  display: block;
  padding: 4px 0 4px 0;
}

input, select {
  padding: 5px;
  margin-top: 10px;
  margin-bottom: 20px;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}

input[type="date"] {
  min-width: 100px; 
  min-height: 35px; 
  background-color: var(--secondary);
  appearance: none;
  -webkit-appearance: none;
  color: var(--tertiary);
  padding-left: 5px;
}

select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  margin: 10px 0 20px 0;
  background: url('data:image/svg+xml;charset=US-ASCII,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="5" viewBox="0 0 10 5"><path fill="%23333" d="M0 0l5 5 5-5z"/></svg>') no-repeat right 11px center;
}

select::-ms-expand {
  display: none;
}

/* Layout Styles */
.pages {
  width: 90%;
  padding: 20px;
  margin: 0 auto;
  white-space: nowrap;
  overflow-x: auto;
}

.page-separation {
  display: flex;
  gap: 50px;
  align-items: flex-start;
}

.clients, .invoices {
  flex: 3;
}

.form-container {
  flex: 1.2;
}

/* Component Styles */
.home {
  position: relative;
  max-width: 80%;
  margin: 40px auto;
  padding: 30px 40px;
  border-radius: 10px;
  background: var(--secondary);
  border: 1px solid var(--primary);
  color: var(--primary);
  text-align: center;
  margin-bottom: 20px;
  overflow-x: auto;
}

@media (max-width: 768px) {
  .home {
    max-width: 90%; 
    padding: 20px 15px; 
    white-space: normal; 
  }
}

.home-details {
  position: relative;
  max-width: 80%;
  margin: 10px auto;
  background: var(--secondary);
  color: var(--tertiary);
}

.modal-show {
  display: block;
  position: initial;
}

.modal-show button {
  margin-top: 10px;
}

.accordion {
  padding: 0 10px;
  border-radius: 4px;
  position: relative;
}

.accordion-body {
  border-radius: 10px;
  position: relative;
  box-shadow: 2px 5px 5px rgba(0, 0, 0, 0.05);
  white-space: normal; 
}

.accordion span {
  position: relative;
  cursor: pointer;
  background: #f1f1f1;
  padding: 3px;
  border-radius: 50%;
  color: var(--primary);
  margin-left: 10px;
}

.accordion h4 {
  margin: 0;
  padding: 5px;
  font-size: 1.1em;
  color: var(--tertiary);
}

.statements-wrapper {
  border-radius: 4px;
  margin: 10px 10px 0px 10px;
  padding: 20px;
  position: relative;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.05);
}

.statement, .no-statements, .no-invoices {
  display: flex;
  align-items: center;
  font-size: 0.9em;
  color: #555;
}

.profile-no-invoices, .profile-no-payments {
  display: flex;
  align-items: center;
  font-size: 0.9em;
  color: #555;
  padding: 10px;
  margin: 10px 0 20px 20px;
}

.profile-invoice-separator {
  border-top: 3px solid var(--secondary);
  border-bottom: 3px solid var(--secondary);
  border-radius: 4px;
}
.profile-payment-separator {
  border-bottom: 3px solid var(--secondary);
  border-radius: 4px;
}

.profile-no-statements {
  display: flex;
  align-items: center;
  font-size: 0.9em;
  color: #555;
  padding: 10px 0 10px 0;
  margin: 10px 0 0 -20px;
}

.statement {
  padding-left: 10px;
}

.no-statements {
  padding: 10px;
}

.no-invoices {
  padding: 0 0 40px 60px;
}

.details {
  background: #fff;
  border-radius: 4px;
  margin: 10px;
  padding: 20px;
  position: relative;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.05);
}

.details h4 {
  margin: 0 0 10px 0;
  font-size: 1.2em;
  color: var(--primary);
}

.details p {
  margin: 0;
  font-size: 0.9em;
  color: #555;
}

.details span {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  background: #f1f1f1;
  padding: 6px;
  border-radius: 50%;
  color: #333;
}

.account-summary {
  background: #fff;
  border-radius: 4px;
  margin: 10px;
  padding: 20px;
  position: relative;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.05);
}

.account-summary h4 {
  font-size: 1.2em;
  color: var(--primary);
}

.account-summary p {
  margin: 0;
  font-size: 0.9em;
  color: #555;
}

.invoices, .clients, .profile {
  background: var(--secondary);
  border-radius: 8px;
  border: 1px solid var(--primary);
}

.invoices h3, .clients h3, .profile h3 {
  color: var(--primary);
  text-align: center;
  border-radius: 4px;
  font-size: 1.75em;
  padding-top: 20px;
}

.profile h5 {
  color: var(--primary);
}

.invoices h5, .clients h5 {
  text-align: flex-start;
  margin: 20px 0;
  font-size: 1.2em;
  color: var(--primary);
}

.statements-wrapper h5 {
  margin: 0 0 10px 0;
  font-size: 1.2em;
}

.profile-invoices h5, .payments-header {
  margin: 10px;
  text-align: center;
  font-size: 1.4em;
}

/* Toggle Switch */

.toggle-switch-container {
  display: flex;
  align-items: center;
  margin: 10px 20px 10px 0px;
  justify-content: space-between;
}

.modal-toggle-switch-container {
display: flex;
}

.toggle-switch-container label {
  margin-right: 10px;
  font-size: 1em;
  color: var(--tertiary);
}

.toggle-switch {
  position: relative;
  width: 50px;
  display: inline-block;
}

.toggle-switch-checkbox {
  display: none;
}

.toggle-switch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  height: 26px;
  width: 50px;
  border: 2px solid var(--primary);
  border-radius: 15px;
  background-color: var(--secondary);
  transition: background-color 0.3s ease-in;
  position: relative;
}

.toggle-switch-label:before {
  content: "";
  display: block;
  width: 18px;
  height: 18px;
  background: var(--primary);
  position: absolute;
  top: 2px;
  left: 3px;
  border-radius: 50%;
  transition: all 0.3s ease-in;
}

.toggle-switch-checkbox:checked + .toggle-switch-label {
  background-color: var(--primary);
}

.toggle-switch-checkbox:checked + .toggle-switch-label:before {
  left: 26px;
  background-color: var(--secondary);
}

/* Forms */

form button {
  background: var(--primary);
  border: 0;
  color: var(--secondary);
  padding: 10px;
  font-family: Georgia, 'Times New Roman', Times, serif;
  border-radius: 40px;
  cursor: pointer;
  margin-top: 10px;
  width: 100%;
}

.input-box {
  position: relative;
}

.input-box span {
  position: absolute;
  right: 20px;
  transform: translateY(-50%);
  font-size: 20px;
  top: 50%;
}

.date-search-wrapper {
  display: flex; 
  justify-content: flex-end;
  align-items: center;
  gap: 20px; 
  margin-top: 10px; 
  margin-right: 20px;
}

.input-date-box {
  display: flex;
  align-items: center; 
  gap: 5px; 
}

.input-date-box p {
  margin: 0;
  font-size: 0.9em; 
  color: var(--primary);
}

.input-date-box input[type="date"] {
  width: 140px;
  padding: 5px 8px;
  border: 1px solid var(--primary);
  border-radius: 4px;
  font-size: 0.9em;
  box-sizing: border-box;
}

form.login, form.signup {
  position: relative;
  max-width: 420px;
  margin: 40px auto;
  padding: 30px 40px;
  border-radius: 10px;
  background: transparent;
  backdrop-filter: blur(10px);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border: 1px solid white;
}

.login input, .signup input {
  padding: 10px 20px;
  margin: 20px 0;
  width: 100%;
  border: 1px solid var(--primary);
  border-radius: 40px;
  box-sizing: border-box;
}

.login h3, .signup h3 {
  text-align: center;
  margin-bottom: 20px;
  color: var(--secondary);
}

.search-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

form.search {
  position: relative;
  width: 100%;
  max-width: 95%; 
  padding: 10px;
}

.search input {
  border: 1px solid  var(--primary);
  box-sizing: border-box;
  border-radius: 4px;
}

.offcanvas-header {
  color: var(--primary);
}

.offcanvas-wrapper h5 {
  color: var(--primary);
}

.create {
  background: var(--secondary);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  margin: 0 auto;
  height: auto;
  border: 1px solid var(--primary);
}

.create h3 {
  text-align: center;
  color: var(--primary);
}

#amountField::placeholder {
  font-size: 12px;
  color: var(--tertiary); 
}
.forgot-register {
  display: flex;
  justify-content: space-between;
  font-size: 14.5px;
  margin: -5px 25px 10px;
}

.forgot-register a {
  text-decoration: none;
  color: var(--secondary);
}

.forgot-register a:hover {
  text-decoration: underline;
}

/* Error Handling */
div.error {
  padding: 10px;
  background: #ffefef;
  border: 1px solid var(--error);
  color: var(--error);
  border-radius: 4px;
  margin: 20px 0;
  white-space: wrap;
}

input.error, select.error {
  border: 1px solid var(--error);
}

/* Utility Classes */
nav {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
}

nav a {
  margin-left: 10px;
}

nav button {
  background: #fff;
  color: var(--primary);
  border: 2px solid var(--primary);
  padding: 6px 10px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
  margin-left: 10px;
}

.nav-left-controls {
  display: flex;
  align-items: center;
}

.nav-right-controls {
  margin-left: auto;
  display: flex;
  align-items: center;
  color: var(--secondary);
}

.nav-right-controls span {
  font-family: 'Times New Roman', Times, serif;
  font-style: italic;
}

.hamburger {
  display: none; 
  background: #fff;
  color: var(--primary);
  border: 2px solid var(--primary);
  padding: 6px 10px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
  margin-right: 10px;
}

@media (max-width: 768px) {
  .hamburger {
    display: block;
  }

  /* Hide nav links by default on small screens */
  .nav-links {
    display: none;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background-color: var(--secondary);
    padding: 10px 0;
    border-top: 1px solid var(--secondary);
    border-radius: 4px;
  }

  .nav-links.active {
    display: flex;
    z-index: 1000;
    width: 40%;
    border: 1px solid var(--primary);
  }

  .nav-links a {
    margin: 5px;
    padding: 5px;
    color: var(--primary);
  }
}

.statements-navbar {
  display: flex;
  align-items: center;
  margin: 0px 10px;
  padding: 5px 10px;
  justify-content: flex-end; 

}

.statements-navbar button {
  background: var(--secondary);
  color: var(--primary);
  border: 2px solid var(--primary);
  padding: 3px 5px;
  border-radius: 4px;
  cursor: pointer;
  font-size: .9em;
  margin-left: 10px;
}

.pagination {
  display: flex;
  justify-content: center;
  padding: 10px 0;
  margin-left: 10px;
  list-style: none;
}

.pagination button {
  margin: 0 5px;
  padding: 5px 10px;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  cursor: pointer;
}

.pagination button:hover {
  background-color: #e9e9e9;
}

.pagination button.active {
  background-color: #007bff;
  color: white;
  border-color: #007bff;
  font-weight: bold;
}

.page-item {
  margin: 0 5px;
}

.page-link {
  padding: 8px 16px;
  text-decoration: none;
  color: #007bff;
  cursor: pointer;
}

.page-item.active .page-link {
  background-color: var(--primary);
  color: white;
  border-radius: 5px;
}

.button-separator {
  display: flex;
  align-items: center;
  gap: 10px;
}
.button-separator .email-statement-modal-button,
.button-separator button {
  background: var(--quaternary);
  border: 0;
  color: var(--secondary);
  padding: 10px 20px; 
  cursor: pointer;
  font-size: 16px; 
  height: 40px; 
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  border-radius: 8px;
  box-sizing: border-box;
}

.email-wrapper {
  position: relative;
  margin: 40px auto;
  padding: 30px 40px;
  border-radius: 10px;
  background: var(--secondary);
  border: 1px solid var(--primary);
  color: var(--tertiary);
  margin-bottom: 20px;
  white-space: nowrap;
  overflow-x: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
}

.email-wrapper th, .email-wrapper td {
  padding: 8px;
  max-width: 500px;
  border: 1px solid var(--primary);
  white-space: wrap;
}

.table-header {
  background-color: #f2f2f2;
  text-align: center;
}

.email-wrapper h3 {
  color: var(--primary);
  text-align: center;
  margin-bottom: 20px;
}

.client-select {
  position: relative;
  width: 100%;
}

.client-select .dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: white;
  border: 1px solid #ccc;
  max-height: 150px;
  overflow-y: auto;
  z-index: 1;
  padding-left: 0;
}

.client-select .dropdown-item {
  padding: 8px;
  cursor: pointer;
}

.client-select .dropdown-item:hover {
  background-color: #f0f0f0;
}

.client-select input::placeholder {
  color: var(--tertiary)
}

.toast {
  max-width: 400px; 
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.toast-body {
  overflow-wrap: break-word;
  word-break: break-word;
  white-space: normal; 
}
